<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-card>
          <ion-card-title
            class="ion-margin-top"
            style="margin-left: 16px; font-weight: bold; font-size: 28px"
          >
            Informes
          </ion-card-title>
          <ion-card-content>
            <ion-row class="ion-justify-content-center">
              <ion-thumbnail style="--size:80px;" class="imgFocus">
                <ion-img
                  src="assets/icon/informes/icoInfSiembra.png"
                  @click="goToInformeAreaSembrada"
                ></ion-img>
              </ion-thumbnail>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-margin-bottom">
              <ion-text style="font-size: 12px; font-weight: bold;">
                Área Sembrada
              </ion-text>
            </ion-row>

            <ion-row class="ion-justify-content-center">
              <ion-thumbnail style="--size:80px;" class="imgFocus">
                <ion-img
                  src="assets/icon/informes/icoInfPlaga.png"
                  @click="goToInformePlagas"
                ></ion-img>
              </ion-thumbnail>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-margin-bottom">
              <ion-text style="font-size: 12px; font-weight: bold;">
                Plagas
              </ion-text>
            </ion-row>

            <!-- <ion-row class="ion-justify-content-center">
              <ion-thumbnail style="--size:80px;" class="imgFocus">
                <ion-img
                  src="assets/icon/informes/icoInfSust.png"
                  @click="goToInformeSustentabilidad"
                ></ion-img>
              </ion-thumbnail>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-margin-bottom">
              <ion-text style="font-size: 12px; font-weight: bold;">
                Sustentabilidad
              </ion-text>
            </ion-row> -->

            <div v-if="false">
              <ion-row class="ion-justify-content-center">
                <ion-thumbnail style="--size:80px;" class="imgFocus">
                  <ion-img
                    src="assets/icon/informes/icoInfRinde.png"
                    @click="volver"
                  ></ion-img>
                </ion-thumbnail>
              </ion-row>
              <ion-row class="ion-justify-content-center ion-margin-bottom">
                <ion-text style="font-size: 12px; font-weight: bold;">
                  Rinde
                </ion-text>
              </ion-row>
            </div>
            <ion-row class="ion-justify-content-center">
              <ion-button @click="volver">
                Volver
              </ion-button>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonText,
  IonRow,
  IonContent,
  IonPage,
  IonButton,
  IonThumbnail,
  IonImg,
  IonCardTitle,
  IonCard,
  IonCardContent
} from "@ionic/vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonContent,
    IonText,
    IonButton,
    IonPage,
    IonRow,
    IonThumbnail,
    IonCardTitle,
    IonImg,
    IonCard,
    IonCardContent
  },
  middleware: ["authenticated", "auth_cliente"],

  computed: {
    ...mapGetters(["isAdmin"])
  },

  methods: {
    goToInformeAreaSembrada() {
      this.$router.push({ name: "informes-area-sembrada" });
    },

    goToInformePlagas() {
      this.$router.push({ name: "informes-plagas" });
    },

    goToInformeSustentabilidad () {
      this.$router.push({ name: "informes-sustentabilidad" });
    },


    volver() {
      /*if (this.isAdmin) {
        this.$router.replace({ name: "admin-productores" });
      } else {
        this.$router.replace({ name: "persona-lotes" });
      }*/
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.margen8px {
  margin-left: 8px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.imgFocus {
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}

ion-card {
  margin-top: 16px;
}
</style>
